import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { AppComponent } from './app.component';
import { AppResolver } from './app.resolver';
import { LogoutComponent } from './auth/logout/logout.component';
import { ErrorComponent } from './components/error-page/error.component';

export const appRoutes: Routes = [
	{
		path: '',
		canActivate: [AuthGuard],
		component: AppComponent,
		resolve: {
			data: AppResolver
		},
		children: [
			{
				path: '',
				canActivate: [AuthGuard],
				loadChildren: () => import('./reports/home/home.module').then((m) => m.HomeModule),
			},
			{
				path: 'availability',
				canActivate: [AuthGuard],
				loadChildren: () => import('./reports/availability/availability.module').then((m) => m.AvailabilityModule),
			},
			{
				path: 'hos-violations',
				canActivate: [AuthGuard],
				loadChildren: () => import('./reports/hos-violations/hos-violations.module').then((m) => m.HosViolationsModule),
			},
			{
				path: 'malfunctions',
				canActivate: [AuthGuard],
				loadChildren: () => import('./reports/malfunctions/malfunctions.module').then((m) => m.MalfunctionsModule),
			},
			{
				path: 'sds',
				canActivate: [AuthGuard],
				loadChildren: () => import('./reports/sds/sds.module').then((m) => m.SDSModule),
			},
			{
				path: 'hours',
				canActivate: [AuthGuard],
				loadChildren: () => import('./reports/hours/hours.module').then((m) => m.HoursModule),
			},
			{
				path: 'odometer',
				canActivate: [AuthGuard],
				loadChildren: () => import('./reports/odometer/odometer.module').then((m) => m.OdometerModule),
			},
			]
	},
	{
		path: 'logout',
		component: LogoutComponent,
	},
	{
		path: 'error',
		component: ErrorComponent,
	},
];


@NgModule({
    imports: [RouterModule.forRoot(appRoutes, {relativeLinkResolution: 'legacy'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
