import { Injectable } from "@angular/core";
import {ActivatedRoute, ActivatedRouteSnapshot, Params, Resolve, Router, RouterStateSnapshot} from "@angular/router";
import { Store } from "@ngrx/store";
import {NoPermissionRoutes, PermissionsService} from "@zonar-ui/auth";
import {combineLatest, EMPTY, forkJoin, from, iif, Observable, of, pipe, throwError, zip} from "rxjs";
import {catchError, delay, filter, finalize, map, switchMap, take} from "rxjs/operators";
import { RootState } from "./app.module";
import {ACTIVE_ACCOUNT_PARAM, LocalStorageService, PREV_COMPANY_ID, SELECTED_ENTITY} from "./local-storage.service";
import { getUserDataSuccess } from "./state/app/app.actions";
import { AppService } from "./state/app/app.service";
import {IUserProfile} from "@zonar-ui/auth/lib/models/user-profile.model";
import {IUser} from "@zonar-ui/auth/lib/models/user.model";
import {Company} from "@zonar-ui/sidenav/lib/models/company.model";
import {environment} from "../environments/environment";
import {ICompany} from "@zonar-ui/auth/lib/models/company.model";
import {IUserGroupPolicy} from "@zonar-ui/auth/lib/models/user-group-policy.model";
import {ChangeCompanyService} from "@zonar-ui/sidenav";
import {DataDogService} from "./datadog.service";

export interface AppRouteData {
    activeAccountSelected: boolean,
    dispatcher?: any,
    activeAccount?: string;
    userProfiles?: IUserProfile[];
    user?: IUser;
    selectedCompany?: Company;
}

@Injectable({
    providedIn: 'root'
})
export class AppResolver implements Resolve<Observable<AppRouteData>> {

    constructor(
        private appService: AppService,
        private datadogService: DataDogService,
        private localStorageService: LocalStorageService,
        private permissionsService: PermissionsService,
        private store: Store<RootState>,
        private noPermissionRoutes: NoPermissionRoutes,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private changeCompanyService: ChangeCompanyService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<AppRouteData> {
        let activeAccount = route.queryParamMap.get('active');

        // This will be used to display progress bar on the app-root.component.ts
        this.appService.isAppDataLoading$.next(true);

        const lastAcct = this.localStorageService.get(ACTIVE_ACCOUNT_PARAM);

        const prevCompanyId = this.localStorageService.get(PREV_COMPANY_ID);

        if(!activeAccount) {
            return from([1]).pipe(
                take(1),
                switchMap(num => combineLatest([
                    this.permissionsService.getCurrentCompanyContext().pipe(
                        filter(x => !!x),
                        take(1),
                        map(comp => comp))
                ])),
                switchMap(([company]) => {
                        if (company?.id === prevCompanyId && lastAcct) {
                            return this.getAppDataWithActiveAccount(lastAcct)
                        } else {
                            return this.getAppDataWithoutActiveAccount(company)
                        }
                    }
                ),
                map(appData => appData),
                catchError(err => {
                    const errorText = err?.message || err || 'Error routing user to active Account for the company';
                    this.datadogService.addRumError(err);
                    this.datadogService.log(errorText, {env: environment.prefix}, 'error');
                    this.router.navigate(
                        ['no-permission-route'],
                        {
                            relativeTo: this.activatedRoute,
                            queryParamsHandling: 'merge', // remove to replace all query params by provided
                        }
                    );
                    return throwError(errorText);
                })
            )
        } else {
            return this.getAppDataWithActiveAccount(activeAccount).pipe(
                catchError(err => {
                    return throwError(err);
                })
            )
        }
    }

    getAppDataWithoutActiveAccount(company: any): Observable<AppRouteData> {
        return from([1]).pipe(
            take(1),
            switchMap((num) => combineLatest([
                    of(company),
                    this.appService.getLegacyAccountCode(company?.id).pipe(filter(x => !!x),
                        map(divs => {
                            const accountMapping = divs.map((x: string) => ({value: x, title: x}));
                            this.appService.setAccountList(accountMapping);
                            return divs;
                        }),
                        catchError(err => {
                            const errorText = `Failure to get LegacyAccounts for company : ${err.message}`;
                            this.appService.isAppDataLoading$.next(false);
                            return throwError(errorText);
                        })),
                ])
            ),
            switchMap(([company, divs]) => {
                let activeAcc = divs[0];
                    //setting the new active account in the localstorage
                this.localStorageService.set(ACTIVE_ACCOUNT_PARAM, activeAcc);
                this.localStorageService.set(PREV_COMPANY_ID, company.id);
                // remove the selectedEntity
                this.localStorageService.remove('selectedEntity');
                return this.getAppDataWithActiveAccount(activeAcc);
            }),
            map(appData => {
                return appData;
            }),
            catchError(err => {
                const errorText = err?.message || err;
                return throwError(errorText);
            })
        )
    }

    getAppDataWithActiveAccount(activeAccount): Observable<AppRouteData> {
        const lastAcct = this.localStorageService.get(ACTIVE_ACCOUNT_PARAM);

        //Here add the permission checks for group policy work
        return this.permissionsService.getIsZonarUser().pipe(
            switchMap((isZonaruser) => {
                if (isZonaruser) {
                    return this.applyZonarUserLoginLogic(activeAccount);
                } else {
                    return this.applyNonZonarUserLoginLogic(activeAccount);
                }
            }),
            switchMap(appData => combineLatest([
                of(appData),
                this.appService.getLegacyAccountCode(appData.selectedCompany.id).pipe(filter(x =>
                    !!x && x !== []),
                    map(divs => {
                        const accountMapping = divs.map((x: string) => ({value: x, title: x}));
                        this.appService.setAccountList(accountMapping);
                        return divs;
                    }),
                    catchError(err => {
                        const errorText = `Failure to get LegacyAccounts for company ${appData.selectedCompany.id}: ${err?.message || err}`;
                        return throwError(errorText);
                    })
                ),
            ])),
            map(([appData, divs]) => {
                this.localStorageService.set(ACTIVE_ACCOUNT_PARAM, activeAccount);
                this.localStorageService.set(PREV_COMPANY_ID, appData?.selectedCompany?.id);
                this.setUserDetails(appData.user, appData.dispatcher, lastAcct);
                return appData;
            }),
            finalize(() => {
                this.appService.isAppDataLoading$.next(false);
            }),
            catchError(err => {
                const errorText = `Error with ActiveAccount ${activeAccount}: ${err.message || err}`;
                console.error(errorText);
                this.datadogService.addRumError(err);
                this.datadogService.log(errorText, {env: environment.prefix}, 'error');
                this.router.navigate(
                    ['no-permission-route'],
                    {
                        relativeTo: this.activatedRoute,
                        queryParamsHandling: 'merge', // remove to replace all query params by provided
                    }
                );
                return throwError(errorText);
            })
        )
    }


    getCompanyLoginMode(companyId?) {
        // determine login mode user has access to based on company
        const index = 0;
        return this.permissionsService.getCompanyLoginMode().pipe(
            filter((x: any[]) => !!x), //filter out null response
            switchMap((companies) => {
                let company;
                if (companyId) {
                    company = companies.find((x) => x.id === companyId);
                } else {
                    company = companies[index];
                }
                this.permissionsService.setCurrentCompanyContextById(company.id); //sets user profile
                this.changeCompanyService.changeSelectedCompany(company.id);
                return of([company]);
            }),
        );
    }

    applyNonZonarUserLoginLogic(activeAccount) {
        return this.getCompanyForNonZonarUser().pipe(
            switchMap(companies => {
                if(companies.length === 1) {
                    this.permissionsService.setCurrentCompanyContextById(companies[0].id); //it should be just one company in the array
                    this.changeCompanyService.changeSelectedCompany(companies[0].id);
                    return this.determineLoginMode(companies[0]);
                } else if (companies.length > 1) {
                    return this.appService.getCompanyByLegacyAccount(activeAccount).pipe(
                        map(comp => {
                            return companies.filter(company => company.id === comp[0].id);
                        }),
                        switchMap(comp => {
                            if(!comp || comp.length === 0) {
                                throw new Error(`companies filtered do not match ${activeAccount}`);
                            }
                            this.permissionsService.setCurrentCompanyContextById(comp[0].id); //it should be just one company in the array
                            this.changeCompanyService.changeSelectedCompany(comp[0].id);
                            return this.determineLoginMode(comp[0]);
                        }),
                        catchError(err => {
                            const errorText = `Failed to getCompany By legacyAccount for ${activeAccount}: ${err.message}`;
                            return throwError(errorText);
                        }))
                } else {
                    throw new Error(`There are no companies associated with this user!`);
                }
            }),
            map(login => {
                // Todo: ZLOGS-18655:
                // if the permissions include not all divisions, and if there are values in the divisions,
                // then make the permissionService.getDivisionMap or get from coereapi: companies/companyID/divisions/divisionId
                // and match the leegacy account code with the activeAccount and then replace the localstorage with the activeaccount and the correct companyId,
                // and remove selectedEntity, and proceed forward since then the getDispatcherIdByEmail request will pass with correct activeAccount
                return login;
            }),
            switchMap((userPermissionInfo) => forkJoin([
                    of(userPermissionInfo),
                    this.permissionsService.getUser().pipe(take(1), map(users => users),
                        catchError(err => {
                            const errorText = `Failed to getUser from Entity /user: ${err.message}`;
                            return throwError(errorText);
                        })),
                    this.permissionsService.getCurrentCompanyContext().pipe(filter(x => !!x), take(1), map(company => company),
                        catchError(err => {
                            const errorText = `Failed to getCompany: ${err.message}`;
                            return throwError(errorText);
                        }))
                ])
            ),
            switchMap(([userPermissionsInfo, user, company]) => combineLatest([
                of(userPermissionsInfo),
                of(user),
                of(company),
                this.appService.getDispatcherIdByEmail(user.email).pipe(take(1),map(id => id),
                    catchError(err => {
                        const errorText = `Unable to getDispatcherByEmail ${user.email} for ${activeAccount}: ${err.message}`;
                        return throwError(errorText);
                    }))
            ])),
            map(([userPermissionsInfo, user, company, id]) => {
                return {
                    activeAccountSelected: !!activeAccount,
                    activeAccount,
                    userProfiles: [{roles: userPermissionsInfo['roles'] || []}],
                    user,
                    selectedCompany: company,
                    dispatcher: id //this is because they are are not a zonar user
                }
            }),
            catchError(err => {
                const errorText = `User failed login: ${err?.message || err}`;
                return throwError(errorText);
            })
        )
    }

    determineLoginMode(company: ICompany) {
        // determine users login mode and retrieve either user profile or group policy
        if (!!company && company.loginMode === 'USER_PROFILE') {
            return this.permissionsService.getUserProfiles().pipe( //used to filter out the correct user profile
                filter((x: any[]) => !!x),
                map(profiles => {
                    let newProfiles = this.getnonZonarUserProfiles(profiles);
                    return newProfiles.filter(nP => nP.companyId === company.id)[0];
                }),
                catchError((err) => {
                    const errorText = `Failed to get user profiles : ${err.message}`;
                    return throwError(errorText);
                })
            )
        } else {
            return this.permissionsService.getUserGroupPolicies().pipe(
                filter((x: any) => !!x),
                switchMap(groupPolicies => {
                    //logic to get the correct group policy for compliance
                    const companyId = company.id;
                    // this filters the group policies to those relevant to the company in an array
                    const gpWithCompany = groupPolicies.filter((gpCompany) => gpCompany.policy.companyId === companyId);
                    const gpWithPerms = [];
                    gpWithCompany.forEach((gp) => {
                        const cdPerms = gp.policy.grants.filter((c: any) => c.application.id === environment.auth.applicationId);
                        if (cdPerms.length > 0) {
                            gpWithPerms.push(gp); //this will / can create duplicates
                        }
                    });
                    return of(gpWithPerms);
                }),
                switchMap(userPolicyPerms => {
                    const policyGroup = this.getPolicyForApp(userPolicyPerms[0]); //takes the first group policy
                    return of(policyGroup);
                }),
                catchError((err) => {
                    const errorText = `Failed to get group policies : ${err?.message || err}`;
                    return throwError(errorText);
                })
            )
        }
    }

    getnonZonarUserProfiles(profiles: IUserProfile[]): IUserProfile[] {
        let userProfile = profiles.filter((c: any) => c.applicationId === environment.auth.applicationId);
        if (!userProfile || userProfile.length === 0) {
            throw new Error('No userprofile found for user');
        };
        if(userProfile.length > 1) {
            const uniqueCompanyProfiles = [];
            userProfile.forEach(p => {
                if(uniqueCompanyProfiles.filter(u => u.companyId === p.companyId).length === 0) {
                    uniqueCompanyProfiles.push(p);
                }
            });
            userProfile = uniqueCompanyProfiles;
        }
        return userProfile;
    }

    getCompanyForNonZonarUser() {
        // determine login mode user has access to based on company
        const index = 0;
        return this.permissionsService.getCompanyLoginMode().pipe(
            filter((x: any[]) => !!x), //filter out null response
            switchMap((companies) => {
                this.permissionsService.setCurrentCompanyContextById(companies[index].id); //sets user profile
                return of(companies);
            }));
    }

    private getPolicyForApp(userRole: IUserGroupPolicy | IUserProfile) {
        if(!userRole) {
            throw new Error('No user role or policy found for user');
        }
        let cdPerms;
        if (userRole && 'policy' in userRole && userRole.policy) {
            cdPerms = userRole.policy.grants.filter((c: any) => c.application.id === environment.auth.applicationId)[0];
        } else {
            if (userRole && 'roles' in userRole) {
                cdPerms = [];
                cdPerms.push({roles: userRole.roles});
            }
        }
        return cdPerms;
    }

    applyZonarUserLoginLogic(acct): Observable<any> {
        return this.permissionsService.getZonarUser().pipe(
            filter(x => !!x),
            switchMap(uPolicy => of(this.getPolicyForApp(uPolicy))), //accepts both policy and profile
            delay(1000),
            switchMap((userPolicyInfo) => combineLatest([
                of(userPolicyInfo),
                this.permissionsService.getUser().pipe(take(1), map(users => users)),
                this.permissionsService.getCurrentCompanyContext().pipe(filter(x => !!x), take(1), map(company => {
                    return company;
                })), //from sidenav - use this to sideNav to grab the company
                this.appService.getCompanyByLegacyAccount(acct).pipe(
                    map(comp => {
                        return comp;
                    }),
                    catchError(err => {
                        const errorText = `Failed to getCompanyByLegacyAccount due to ${err?.message || err}`;
                        return throwError(errorText);
                    })),
            ])),
            switchMap(([zonarUserGroupPolicy, user, company, acCompany]) => {
                let companyForContext = company;
                const userPerm = {
                    roles: zonarUserGroupPolicy?.roles
                };
                if (acCompany && acCompany.length > 0) {
                    //there is an active account company
                    companyForContext = acCompany[0];
                }
                const companyId = companyForContext?.id;
                this.permissionsService.setCurrentCompanyContextById(companyId);
                this.changeCompanyService.changeSelectedCompany(companyId);
                return of({
                    activeAccountSelected: !!acct,
                    activeAccount : acct,
                    userProfiles: [userPerm],
                    user,
                    selectedCompany: companyForContext,
                    dispatcher: null //this is because they are are zonar user
                });
            }),
            catchError(err => {
                const errorText = `Zonar user failed login: ${err?.message || err}`;
                return throwError(errorText);
            })
        );
    }

    public setUserDetails(user: any, dispatcher: any, lastAcct: string) {
        const selectedEntity = this.localStorageService.get(SELECTED_ENTITY) || {
            label: ``,
            value: '',
            type: '',
            entityType: '',
        }
        const activeAcct = this.localStorageService.get(ACTIVE_ACCOUNT_PARAM);
        if (dispatcher && dispatcher.id && (lastAcct !== activeAcct || !selectedEntity.value)) {
            selectedEntity.value = `${activeAcct}:${dispatcher.id}`;
            selectedEntity.label = `${user.firstName} ${user.lastName}`;
            selectedEntity.type = 'dispatcherId';
            selectedEntity.entityType = 'dispatcherId';
            this.localStorageService.set(SELECTED_ENTITY, selectedEntity);
        } else  if(selectedEntity.value && lastAcct !== activeAcct) {
            // Clear previous selection, let user choose new entity from location/dispatcher on new account selection.
            this.localStorageService.remove(SELECTED_ENTITY);
            selectedEntity.label = '';
            selectedEntity.value = '';
            selectedEntity.type = '';
            selectedEntity.entityType = '';
        }
        const data = {
            userId: user.id,
            displayName: selectedEntity.label,
            companyName: activeAcct,
            divisions: [],
            isAdmin: this.appService.isZonarAdmin(user) || this.appService.isCustomerAdmin(activeAcct),
            selectedEntity,
        }
        this.store.dispatch(getUserDataSuccess(data))
    }
}
